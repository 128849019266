import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import Swiper from 'swiper/swiper-bundle';
import 'swiper/swiper-bundle.min.css';

window.Fancybox = Fancybox;

const swiper = new Swiper('.swiper', {
    loop: true,
    slidesPerView: 1,
    autoplay: {
      delay: 3000
    },
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
  });
  